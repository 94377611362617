<template>
  <v-container>
    <v-snackbar v-model="snackbar" :timeout="4000" top :color="colorText">
      <span>{{ message }}</span>
      <v-btn text color="white" @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-row
      class="d-flex flex-row container"
      style="margin-top: -15px; margin-left:10px; margin-right:20px"
    >
      <v-col cols="12" sm="12">
        <v-toolbar
          color="transparent"
          flat
          elevation="0"
          class="mb-6 title mt-1"
          height="55px"
          ><v-row>
            <v-col class="mb-n4" cols="12" sm="12" md="12" lg="12" xl="12">
              <v-card-text
                class="mb-n7 ml-n7 blue-grey--text"
                style="font-size: 18px; font-weight: bold"
                >Add New Member</v-card-text
              >
              <h6 class="mt-2 ml-n3 blue-grey--text">
                Provide customer details for the field below
              </h6>
            </v-col></v-row
          >
        </v-toolbar>

        <v-alert class="mx-2 mb-4" dense v-show="alert" type="success">
          {{ message }}
        </v-alert>
      </v-col>
    </v-row>
    <v-card tile class="d-flex container">
      <v-col>
        <div>
          <h5>
            <v-icon color="black" class="ma-1" size="15"
              >mdi-crop-square</v-icon
            >
            Customer Address
          </h5>
        </div>
        <v-row class="d-flex justify-space-between mt-2">
          <v-text-field
            dense
            outlined
            label="First Name"
            ref="firstName"
            class="mx-5 px-2 required"
            v-model="user.firstName"
            :rules="[rules.requiredField]"
          ></v-text-field>
          <v-text-field
            dense
            outlined
            label="Last Name"
            ref="lastName"
            class="mx-5 px-2 required"
            v-model="user.lastName"
            :rules="[rules.requiredField]"
          >
          </v-text-field>
          <v-text-field
            dense
            outlined
            label="Date of Birth"
            ref="name"
            class="mx-5 px-2 required"
            v-model="user.birthDate"
            :rules="[rules.requiredField]"
          ></v-text-field>
        </v-row>
      </v-col>
      <v-col>
        <div>
          <h5>
            <v-icon color="black" class="ma-1" size="15">mdi-crop-square</v-icon
            >Customer Contact
          </h5>
        </div>
        <v-row class="d-flex justify-space-between mt-2">
          <v-text-field
            dense
            outlined
            label="Email"
            ref="name"
            class="mx-5 px-2 required"
            v-model="user.emailAddress"
            :rules="[rules.requiredName]"
          ></v-text-field>
          <v-text-field
            dense
            outlined
            label="Phone"
            ref="phone"
            class="mx-5 px-2 required"
            v-model="user.phoneNo"
            :rules="[rules.requiredEmail]"
          >
          </v-text-field>
          <v-text-field
            dense
            outlined
            label="Resident Address"
            ref="name"
            class="mx-5 px-2 required"
            v-model="user.residentAddress"
            :rules="[rules.requiredField]"
          ></v-text-field>
        </v-row>
      </v-col>
      <v-col>
        <div>
          <h5>
            <v-icon color="black" class="ma-1" size="15">mdi-crop-square</v-icon
            >Customer Account
          </h5>
        </div>
        <v-row class="d-flex justify-space-between mt-2">
          <v-text-field
            dense
            outlined
            label="Account Number"
            ref="name"
            class="mx-5 px-2 required"
            v-model="user.accountNumber"
            :rules="[rules.requiredAccountNumber]"
          ></v-text-field>
          <v-text-field
            dense
            outlined
            label="National ID"
            ref="email"
            class="mx-5 px-2 required"
            v-model="user.nationalID"
            :rules="[rules.requiredField]"
          >
          </v-text-field>
          <v-text-field
            dense
            outlined
            label="Registration Number"
            ref="registrationNo"
            class="mx-5 px-2 required"
            v-model="user.registrationNo"
            :rules="[rules.requiredField]"
          ></v-text-field>
        </v-row>
      </v-col>
      <v-col>
        <div>
          <h5>
            <v-icon color="black" class="ma-1" size="15">mdi-crop-square</v-icon
            >Customer Bio Details
          </h5>
        </div>
        <!--        <v-row class="d-flex justify-space-between mt-2">-->
        <!--          <v-text-field-->
        <!--            dense-->
        <!--            outlined-->
        <!--            label="Name"-->
        <!--            ref="name"-->
        <!--            class="mx-5 px-2 required"-->
        <!--            v-model="user.name"-->
        <!--            :rules="[rules.requiredName]"-->
        <!--          ></v-text-field>-->
        <!--          <v-text-field-->
        <!--            dense-->
        <!--            outlined-->
        <!--            label="Email"-->
        <!--            ref="email"-->
        <!--            class="mx-5 px-2 required"-->
        <!--            v-model="user.email"-->
        <!--            :rules="[rules.requiredEmail]"-->
        <!--          >-->
        <!--          </v-text-field>-->
        <!--          <v-text-field-->
        <!--            dense-->
        <!--            outlined-->
        <!--            label="Account Number"-->
        <!--            ref="name"-->
        <!--            class="mx-5 px-2 required"-->
        <!--            v-model="user.accountNumber"-->
        <!--            :rules="[rules.requiredAccountNumber]"-->
        <!--          ></v-text-field>-->
        <!--        </v-row>-->
      </v-col>
      <v-col>
        <hr style="margin: 0px 20px" />
      </v-col>
      <v-col>
        <v-row class="d-flex justify-space-between mt-2">
          <v-spacer />
          <div></div>
          <div style="margin-right: 40px">
            <v-btn small class="ma-1" @click.stop="cancel">Cancel </v-btn>
            <v-btn
              small
              class="ma-1 light-green white--text"
              @click.stop="addNewMember"
              >Save
            </v-btn>
          </div>
        </v-row>
      </v-col>
    </v-card>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiServices";

export default {
  name: "AddNewCustomer",
  data() {
    return {
      alert: false,
      formHasErrors: false,
      rules: {
        min: value => value.length >= 10 || "Must be 10 digits",
        requiredEmail: value => !!value || "Email is required.",
        requiredValidEmail: value =>
          /^[a-z0-9+_.-]+@[a-z0-9.-]+$/.test(value) || "Invalid Email address",
        requiredName: value => !!value || "Name is required.",
        requiredAccountNumber: value => !!value || "AccountNumber is Required.",
        requiredField: value => !!value || "Field is Required."
      },
      model: 1,
      message: "",
      slug: "",
      count: 4,
      dialog: false,
      progress: false,
      user: {
        firstName: "",
        lastName: "",
        phoneNo: "",
        accountNumber: "",
        birthDate: "",
        sex: "",
        residentAddress: "",
        registrationNo: "",
        nationalID: "",
        emailAddress: ""
      },
      email: "",
      snackbar: false,
      colorText: "",
      staff: []
    };
  },

  methods: {
    cancel() {
      this.$router.push({
        name: "admin.customers.all"
      });
    },

    async addNewMember() {
      try {
        this.showLoading = true;
        const results = await ApiService.addNewMembersFroAdmin(this.user);
        console.log(results);
        if (results && results.data.success) {
          this.snackbar = true;
          console.log(results);
          this.colorText = "success";
          console.log(results.data.success);
          this.dialog = false;
          this.showLoading = true;
          setTimeout(() => {
            this.$router.push({
              name: "admin.customers.all"
            });
          }, 2000);
        }
      } catch (error) {
        this.showLoading = true;
        console.log(error);
        if (error.response.success === false) {
          this.message = "Incorrect Account Number or Password";
          this.snackbar = true;
          this.colorText = "warning";
        } else {
          this.message = "Internal Server Error";
          this.snackbar = true;
          this.colorText = "warning";
        }
      }
    }
  }
};
</script>

<style scoped></style>
